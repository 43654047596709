import React from 'react';
import { Box, Typography, Divider, List, ListItemButton, ListItemText } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { useModal } from '../Commons/ModalContext';
import EditSettings from '../Settings/EditSettings';
import ThemeToggleButtonGroup from '../Commons/ThemeToggleButtonGroup';

interface MobileMenuProps {
  onClick: () => void;
}

const MobileMenu: React.FC<MobileMenuProps> = ({ onClick }) => {
  const { user, logout } = useAuth0();

  const { openModal } = useModal();

  const navigate = useNavigate();

  if (!user || !user.sub) {
    throw new Error("User or User.sub is not defined");
  }

  // Obtener el user ID
  const userId = Number(user.sub.split('|')[1]);

  // IDs permitidos
  const allowedUserIds = [13, 24]; // Números enteros

  const handleOpen = () => {
    onClick();
    openModal(<EditSettings />);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          textAlign: 'center',
        }}
      >
        <Typography variant="h6" sx={{ my: 2 }}>
          News
        </Typography>

        <Typography variant="caption" sx={{ my: 2 }}>
          {user.name} {user.nickname}
        </Typography>
        <Divider />
        <List>
          <ListItemButton onClick={() => { navigate('/news') }} sx={{ textAlign: 'center' }}>
            News feed
          </ListItemButton>
          {/* Mostrar AI Report Preview solo para los usuarios permitidos */}
          {allowedUserIds.includes(userId) && (
            <ListItemButton onClick={() => { navigate('/report-preview') }} sx={{ textAlign: 'center' }}>
              AI Report Preview
            </ListItemButton>
          )}
          <ListItemButton onClick={handleOpen} sx={{ textAlign: 'center' }}>
            Settings
          </ListItemButton>
        </List>
      </Box>
      <Box
        sx={{
          textAlign: 'center',
          p: 2,
        }}
      >
        <ThemeToggleButtonGroup />
      </Box>
      <Box
        sx={{
          textAlign: 'center',
          p: 2,
        }}
      >
        <List>
          <ListItemButton
            onClick={() => {
              logout({ logoutParams: { returnTo: window.location.origin } });
            }}
            sx={{ textAlign: 'center' }}
          >
            Logout
          </ListItemButton>
        </List>
        <Typography variant="caption" sx={{ mt: 2, fontSize: '0.75rem' }}>
          Version 2.4.23
        </Typography>
      </Box>
    </Box>
  );
};

export default MobileMenu;