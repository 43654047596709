
// Routing
import React from 'react';
import { Route, Routes, useRouteError } from 'react-router-dom';
import PostScreen from './Screens/PostScreen';
import PostsFeedScreen from './Screens/PostsFeedScreen';
import NewsChatScreen from './Screens/NewsChatScreen';
import SettingsScreen from './Screens/SettingsScreen';
import ReportPreviewScreen from './Screens/ReportPreviewScreen';

function ErrorBoundary() {
  let error = useRouteError();
  console.error(error);
  // Uncaught ReferenceError: path is not defined
  return <div>{JSON.stringify(error)}</div>;
}

const Routing = () => {
  return (
    <Routes>
      <Route path="/news/*" errorElement={<ErrorBoundary />}>
        <Route index element={<PostsFeedScreen />} />
        <Route path="post" element={<PostScreen />}></Route>
        <Route path=":id" element={<PostScreen />}></Route>
      </Route>
      
      <Route path="report-preview" element={<ReportPreviewScreen />} />
      <Route path="chat" element={<NewsChatScreen />} />
      <Route path="settings" element={<SettingsScreen />} />
      <Route path="/" element={<PostsFeedScreen />} />
    </Routes >
  )
};

export default Routing;