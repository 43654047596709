import { IconButton } from "@mui/material";
import { SearchIcon } from "lucide-react";
import { useLayout } from "./Layout";
import { useFilters } from "../PostsFeed/Components/Filters/FiltersProvider";

// Component to toggle the visibility of the search bar
const ToggleSearchBarButton: React.FC = () => {
  // Using the App Filters.
  const [, setFilters] = useFilters();

  const { isSearchByClouseOpen, setIsSearchByClouseOpen } = useLayout();

  // Toggle search bar visibility state when button is clicked
  const handleSearchClick = () => {
    setIsSearchByClouseOpen(isSearchByClouseOpen => {
      if (!isSearchByClouseOpen) setFilters(filters => ({ ...filters, searchClause: null }))
      return !isSearchByClouseOpen
    });
  };

  return (
    <IconButton
      sx={{ color: !isSearchByClouseOpen ? 'white' : 'gray' }}
      onClick={handleSearchClick}
      aria-label="toggle-search"
      size="large"
    >
      <SearchIcon size='1.2rem' />
    </IconButton>
  );
};

export default ToggleSearchBarButton;