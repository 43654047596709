import React, { useCallback } from "react";
import { useFeedsState } from "../../Providers/FeedsStateProvider";
import InputSelectStatus from "../commons/Inputs/InputSelectPostsStatus";

import { SelectChangeEvent, InputLabel, TextField,  FormControl } from "@mui/material";
import { FEEDS } from "../../Providers/FeedsStateProvider";
import { styled } from "@mui/material/styles";
import Select from "@mui/material/Select";
import InputSelectOrderBy from "../commons/Inputs/InputSelectOrderBy";
import { useFilters } from "./FiltersProvider";
import TickerInputSelectBase from "../commons/TickerInputSelect";
import UserAnalystInputSelect from "../commons/UserAnalystInputSelect";

export const FiltersInputsStyledFormControl = styled(FormControl)(({ theme }) => ({
	color: 'inherit',
	[theme.breakpoints.down('sm')]: {
		width: '100%'
	}
}));

export const filtersInputsCommonStyles = {
	color: "white", // "MuiInputBase-root
	"text-fill-color": "white !important",

	borderColor: "rgba(228, 219, 233, 0.25)",
	"& .MuiOutlinedInput-notchedOutline": {
		borderColor: "rgba(228, 219, 233, 0.25)",
	},

	".MuiSvgIcon-root": { fill: "white !important" },

	'.MuiOutlinedInput-notchedOutline': {
		borderColor: 'rgba(228, 219, 233, 0.25)',
	},
	'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
		borderColor: 'rgba(228, 219, 233, 0.25)',
	},
	'&:hover .MuiOutlinedInput-notchedOutline': {
		borderColor: 'rgba(228, 219, 233, 0.25)',
	},
};

const selectCommonStyles = {
	...filtersInputsCommonStyles,

	input: {
		color: ' white',
		fontSize: 15.5,
		height: 15.5,
		padding: 1,
		width: 1,
		minWidth: '200px'
	},
	label: {
		transform: 'translate(14px, 6px) scale(1) !important',
	},
	"& .MuiInputLabel-root.MuiInputLabel-shrink": {
		fontSize: '.7rem',
		transform: 'translate(14px, -7px) scale(1) !important',
	},
};

const CustomStyledSelect = styled(Select)(({ theme }) => ({
	...filtersInputsCommonStyles,
	height: '2em',
}));

export const FilterInputSelectStatus: React.FC = () => {
	const { useFeedState } = useFeedsState();
	const [feedsState, setFeedsState] = useFeedState;

	// Handler for changes in the Select component
	const handleStatusChange = useCallback(
		(event: SelectChangeEvent<unknown>) => {
			const newValue = event.target.value as FEEDS | number;
			setFeedsState(newValue);
			console.log(`Status changed to: ${newValue}`);
		},
		[setFeedsState]
	);

	return (
		<InputSelectStatus
			feedsState={feedsState}
			onStatusChange={handleStatusChange}
			StyledFormControlProps={{
				sx: {
					minWidth: "75px",
				}
			}}
			// Provide only one of the props: `label` or `CustomInputLabel`
			CustomInputLabel={
				<InputLabel id="filter-status" sx={{ color: "white" }}>
					Status
				</InputLabel >
			}
			CustomSelectComponent={CustomStyledSelect}
		/>
	);
};

export const FilterInputOrderBy: React.FC = () => {
	// Retrieve the current filters and the function to update them from the context
	const [filters, setFilters] = useFilters();

	return (
		<>
			{/* Using the context state (`filters`) and passing a custom InputLabel and Select component */}
			<InputSelectOrderBy
				// sx={{ "& .MuiInputBase-input": { fontSize: 18, height: 18, padding: 1 } }}
				orderDatesBy={filters.orderDatesBy} // Use the orderDatesBy value from the context
				onOrderByChange={(event) => {
					// Update the filters context when the order changes
					setFilters((prevFilters: any) => ({
						...prevFilters,
						orderDatesBy: event.target.value, // Update the `orderDatesBy` value
					}));
				}}
				StyledFormControlProps={{
					// sx: { m: 0.5, "& .MuiInputBase-input": { fontSize: 18, height: 18, padding: 1 }, }
				}}
				CustomInputLabel={
					<InputLabel id="filter-order-by" sx={{ color: "white" }}>
						Order By
					</InputLabel>
				}
				CustomSelectComponent={CustomStyledSelect} // Use a custom Select component for styling
			/>
		</>
	);
};


export const FilterInputSelectTicker: React.FC = () => {
	const [filters, setFilters] = useFilters();

	return (
		<TickerInputSelectBase
			size="small"
			sx={{
				...selectCommonStyles,
				minWidth: 150,
			}}
			value={filters.ticker}
			onChange={(event, ticker) => { setFilters(f => ({ ...f, ticker })); }}
			renderInput={(params) => <TextField  {...params} label="Ticker" />}
		/>
	);
};


export const AnalystsFilterInput = (props: {}) => {
	const [filters, setFilters] = useFilters();

	return (
		<FiltersInputsStyledFormControl size="small">
			<UserAnalystInputSelect
				value={filters.analyst}
				onChange={(event, user) => {
					setFilters(f => ({ ...f, analyst: user }))
				}}
				sx={{
					...selectCommonStyles,
					"& .MuiInputBase-input": {
						minWidth: '60px !important',
					},
				}}
				size="small" />
		</FiltersInputsStyledFormControl>
	)

}