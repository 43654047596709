// ReportPreviewScreen.tsx
import React, { useEffect, useState, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Layout from '../Components/Layout/Index';
import MarketReportPreview from '../Components/WIP/MarketReportPreview';

const ReportPreviewScreen: React.FC = () => {

  return (
    <Layout sx={{ padding: 2 }}>
      <MarketReportPreview />
    </Layout>
  );
};

export default ReportPreviewScreen;
