import React, { useState, useRef, createContext, useContext } from "react";
import { StateSnapshot, VirtuosoHandle } from "react-virtuoso";

// Define the type for the context value
interface FeedIndexContextType {
  currentPostIndex: number | null;
  setCurrentPostIndex: React.Dispatch<React.SetStateAction<number | null>>;
  ref: React.RefObject<VirtuosoHandle>;
  virtuosoState: React.MutableRefObject<StateSnapshot | undefined>;
}

// Create the context
const FeedIndexContext = createContext<FeedIndexContextType | null>(null);

// Create the custom hook for accessing the feed index
export const useFeedIndex = (): FeedIndexContextType => {
  const context = useContext(FeedIndexContext);
  if (!context) {
    throw new Error("useFeedIndex must be used within a FeedIndexProvider");
  }
  return context;
};

// Create the provider component
export const FeedIndexProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [currentPostIndex, setCurrentPostIndex] = useState<number | null>(null);
  const ref = useRef<VirtuosoHandle>(null);
  const virtuosoState = useRef<StateSnapshot | undefined>(undefined);

  return (
    <FeedIndexContext.Provider value={{ currentPostIndex, setCurrentPostIndex, ref, virtuosoState }}>
      {children}
    </FeedIndexContext.Provider>
  );
};

export default FeedIndexProvider;
