import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Alert,
  Card,
  CardContent,
  Divider,
  TextField,
} from "@mui/material";

const MarketReportPreview: React.FC = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [extractedData, setExtractedData] = useState<any | null>(null);
  const [formattedPreview, setFormattedPreview] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleFileSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const processReportPreview = async () => {
    if (!selectedFile) {
      alert("Please select a PDF file to process!");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    setIsLoading(true);
    setErrorMessage(null);

    try {
      // First request: Extract data from the first page of the PDF using GPT
      const extractResponse = await fetch("https://api.forwardci.co/AIPrototypes/extract", {
        method: "POST",
        body: formData,
      });

      if (!extractResponse.ok) {
        throw new Error(`Error extracting data: ${extractResponse.statusText}`);
      }

      const extractData = await extractResponse.json();
      setExtractedData(extractData);

      // Prepare payload for the second request
      const formatRequestBody = {
        data: extractData.data,
        message: extractData.message,
      };

      // Second request: Format the preview (title and body) based on the extracted data
      const formatResponse = await fetch("https://api.forwardci.co/AIPrototypes/create_post_from_extrack", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formatRequestBody),
      });

      if (!formatResponse.ok) {
        throw new Error(`Error formatting preview: ${formatResponse.statusText}`);
      }

      const formatData = await formatResponse.json();
      setFormattedPreview(formatData);
    } catch (err: any) {
      setErrorMessage(err.message || "Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ padding: "20px", fontFamily: "Arial, sans-serif", maxWidth: "600px", margin: "auto" }}>
      <Typography variant="h5" gutterBottom>
        Market Report Preview
      </Typography>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        This tool provides a <strong>preview</strong> of the post format, based on the content extracted from the <strong>first page</strong> of the selected PDF file.
      </Typography>
      <Typography variant="body2" color="error" gutterBottom>
        <strong>Warning:</strong> This tool still requires validation before it can be fully integrated with the news system.
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <Button variant="contained" component="label">
          Select PDF File
          <input type="file" hidden onChange={handleFileSelection} />
        </Button>
        {selectedFile && <Typography variant="body1">{selectedFile.name}</Typography>}
        <Button variant="contained" color="primary" onClick={processReportPreview} disabled={isLoading}>
          Generate Preview
        </Button>
      </Box>
      {isLoading && (
        <Box sx={{ marginTop: 2 }}>
          <CircularProgress />
          <Typography variant="body2" sx={{ marginTop: 1 }}>
            Processing...
          </Typography>
        </Box>
      )}
      {errorMessage && (
        <Alert severity="error" sx={{ marginTop: 2 }}>
          Error: {errorMessage}
        </Alert>
      )}
      {formattedPreview && (
        <Box sx={{ marginTop: 4 }}>
          <Typography variant="h6" gutterBottom>
            Generated Preview
          </Typography>
          <Card>
            <CardContent>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                Ticker:
              </Typography>
              <Typography variant="body1">{formattedPreview.post.Ticker}</Typography>
              <Divider sx={{ my: 2 }} />
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                Title:
              </Typography>
              <Typography variant="body1">{formattedPreview.post.title}</Typography>
              <Divider sx={{ my: 2 }} />
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                Body:
              </Typography>
              <Typography variant="body1">{formattedPreview.post.body || "No content provided"}</Typography>
              <Divider sx={{ my: 2 }} />
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                Metadata:
              </Typography>
              <TextField
                multiline
                fullWidth
                value={JSON.stringify(formattedPreview.post.metadata, null, 2)}
                variant="outlined"
                slotProps={{
                  input: {
                    readOnly: true,
                  },
                }}
                sx={{ marginTop: 2, backgroundColor: "#f4f4f4", borderRadius: "5px" }}
              />
            </CardContent>
          </Card>
        </Box>
      )}
    </Box>
  );
};

export default MarketReportPreview;