import React from 'react';
import { createTheme } from '@mui/material/styles';
import { blue } from '@mui/material/colors';

// Extend the default theme to include custom color and typography variants
declare module '@mui/material/styles' {
  interface TypographyVariants {
    ticker: React.CSSProperties;
    date: React.CSSProperties;
    time: React.CSSProperties;
    postContent: React.CSSProperties;
    postTitle?: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    ticker?: React.CSSProperties;
    date?: React.CSSProperties;
    time?: React.CSSProperties;
    postContent?: React.CSSProperties;
    postTitle?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    ticker: true;
    postTitle: true;
    postContent: true;
    date: true;
    time: true;
    h1: false;
    h2: false;
    h3: false; // Disabled variant
  }
}

// Extend ButtonProps to include custom colors and variants
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    action: true;
  }
  interface ButtonPropsColorOverrides {
    reject: true;
    delete: true;
    blue: true;
  }
}



// Define the custom theme using createTheme
const theme: Parameters<typeof createTheme>[0] = {
  colorSchemes: {
    dark: {
      palette: {
        background: {
          paper: '#37474f',  // Dark mode background color for Drawer
        },
      },
    },
    light: {
      palette: {
        background: {
          default: '#f9f7f1'
        },
        primary: {
          main: '#26a69a',
          dark: '#26a69a',
        },
      }
    },
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: ({ theme }) => ({
          backgroundColor: theme.palette.mode === 'dark' ? '#37474f' : theme.palette.primary.main,  // Dynamic background based on theme mode
        })
      }
    },
    MuiOutlinedInput: {
      defaultProps: {
        sx: {
          // fontSize: "1.5rem", // Increase font size for outlined input
        },
      }
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'action' },
          style: {
            textTransform: 'uppercase',
            color: 'white',
            letterSpacing: `.5px`,
            border: `2px dashed grey${blue[500]}`,
            height: `54px`,
            lineHeight: `56px`,
            minWidth: `150px`,
            fontSize: `1rem`
          },
        },
        {
          props: { variant: 'action', color: 'primary' },
          style: {
            backgroundColor: '#26a69a',
            letterSpacing: `.5px`,
            lineHeight: `56px`,
            "&:hover": {
              backgroundColor: `#2bbbad`,
            },
          },
        },
        {
          props: { variant: 'action', color: 'reject' },
          style: {
            backgroundColor: '#F44336',
            "&:hover": {
              backgroundColor: `#F6685E`,
            },
          },
        },
        {
          props: { variant: 'action', color: 'blue' },
          style: {
            backgroundColor: '#2196F3',
            "&:hover": {
              backgroundColor: `#1769AA`,
            },
          },
        },
      ]
    },
  },
  typography: {
    fontFamily: 'roboto',
    ticker: {
      fontSize: '1.2rem',
      fontWeight: 'bold'
    },
    date: {
      color: 'rgb(21, 101, 192)',
      fontWeight: '500'
    },
    time: {
      color: 'rgb(21, 101, 192)',
      fontWeight: '500'
    },
    postContent: {
      fontWeight: '500'
    },
    postTitle: {
      fontWeight: '500'
    },
  }

};

export default theme;
