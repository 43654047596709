import React from 'react';
import { Box, Typography, Divider, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import TickerSortOrderToggleBtn from '../PostsFeed/Components/Filters/TickerSortOrderToggleBtn';
import DateRangeFilterInput from '../PostsFeed/Components/Filters/DateRangeFilterInput';
import ToggleSearchBarButton from './ToggleSearchBarButton';
import { AnalystsFilterInput, FilterInputOrderBy, FilterInputSelectStatus, FilterInputSelectTicker } from '../PostsFeed/Components/Filters/FiltersInputs';

interface Iprops { }
const centerFlex = { justifyContent: 'center' };
const FilterDrawer: React.FC<Iprops> = (props) => {
  return (
    <Box
      // onClick={handleDrawerToggle}
      sx={{
        textAlign: 'center'
      }}>

      <Typography variant="h6" sx={{ my: 2 }}>
        Filters
      </Typography>
      <Divider />
      <List>
        <ListItem sx={{ ...centerFlex }}>
          <ToggleSearchBarButton />
          {/* <IconButton sx={{ color: (!props.isSearchEnable) ? 'white' : 'gray' }}
            onClick={props.onSearchClick} aria-label="delete" size="large">
            <SearchIcon fontSize="inherit" />
          </IconButton> */}
        </ListItem>
        <ListItem sx={{ ...centerFlex }}>
          <FilterInputSelectStatus />
        </ListItem>
        <ListItem sx={{ ...centerFlex }}>
          <FilterInputSelectTicker />
        </ListItem >
        <ListItem sx={{ ...centerFlex }}>
          <Box>
            <TickerSortOrderToggleBtn />
          </Box>
        </ListItem>
        <ListItem sx={{ ...centerFlex }}>
          <FilterInputOrderBy />
        </ListItem>
        <ListItem sx={{ ...centerFlex }}>
          <Box >
            <DateRangeFilterInput />
          </Box>
        </ListItem>

        <ListItem sx={{ ...centerFlex }}>
          <Box>
            <AnalystsFilterInput />
          </Box >
        </ListItem>
        {
          [].map((item) => (
            <ListItem key={item} disablePadding>
              <ListItemButton sx={{ textAlign: 'center' }}>
                <ListItemText primary={item} />
              </ListItemButton>
            </ListItem>
          ))
        }
      </List >
    </Box >
  )
};

export default FilterDrawer;